import React from "react";
import Helmet from "react-helmet";
import PageLayout from '../components/layouts/layout-page.js';
import contentStyle from './404.module.css';

const ErrorPage404 = () => {
  const pageInfo = {
    title: "Elastic Path Documentation - Page not found",
    description: "Go back to the Documentation home page to try your search again.",
    url: "https://documentation.elasticpath.com/404/"
  }

  return (
    <PageLayout pageInfo={pageInfo}>
        <h2 className={"sectionHeading " + contentStyle.heading404}>404</h2>
        <h3 className={"sectionHeading " + contentStyle.headingOops}>Oops!</h3>
        <p className={contentStyle.errorMessage}>We can’t find what you’re looking for.</p>

        <Helmet>
            <script type="module" src="https://documentation.elasticpath.com/_lib/ep-webcomponent/public/EpButtonCTA.js" />
        </Helmet>

        <div className={contentStyle.homeButtonContainers}>
          <ep-button-cta label="Go to Composable Commerce Docs" href="/"></ep-button-cta>
          <ep-button-cta label="Go to Self Managed Commerce Docs" href="/commerce-docs"></ep-button-cta>
        </div>
    </PageLayout>
  )
}

export default ErrorPage404
